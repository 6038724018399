import 'ts-polyfill'
import index from './dnloader/index'
import './index.css'
if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}
index()
