import { writeHeapSnapshot } from 'v8'

declare global {
  interface Document {
    ready: (callback: () => void) => void
  }
}

interface Wrap {
  wrap: (domNode: HTMLElement) => void
}

type DependencyMap = {
  [key: string]: Promise<Wrap>
}

const map: DependencyMap = {
  leaflet: import('./leaflet/index'),
  'locations-map': import('./locations-map/index'),
}

const runWrapper = async (dependency: string, domNode: HTMLElement) => {
  const wrapper: Wrap = await map[dependency]
  if (wrapper) {
    wrapper.wrap(domNode)
  }
}

const init = () => {
  Document.prototype.ready = (callback: () => void) => {
    if (typeof callback === 'function') {
      document.addEventListener('DOMContentLoaded', () => {
        if (['interactive', 'complete'].includes(document.readyState)) {
          return callback()
        }
      })
    }
  }

  document.ready(() => {
    // fetch dependencies
    const dependencies: { [key: string]: HTMLElement[] } = {}
    const elements = document.querySelectorAll<HTMLElement>('*[data-dn-loader]')
    elements.forEach(elem => {
      const dependency = elem.getAttribute('data-dn-loader')
      if (dependency) {
        if (!dependencies.hasOwnProperty(dependency)) {
          dependencies[dependency] = []
        }
        dependencies[dependency].push(elem)
      }
    })

    Object.keys(dependencies).forEach(dependency => {
      dependencies[dependency].forEach(node => {
        runWrapper(dependency, node)
      })
    })
  })
}

export default init
